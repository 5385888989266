<div class="flex flex-col gap-2">
  <mat-nav-list>
    @for (page of pages; track page.route) {
      <mat-list-item [routerLink]="page.route" [activated]="router.url.includes(page.route)">
        <mat-icon matListItemIcon>{{ page.icon }}</mat-icon>
        {{ page.title }}
      </mat-list-item>
    }
  </mat-nav-list>
</div>
