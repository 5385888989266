<div class="flex flex-col h-full">
  <glist-layout-feature-header (menuClicked)="sideNav.toggle()"></glist-layout-feature-header>
  <mat-drawer-container class="grow" [hasBackdrop]="deviceService.isMobile()">
    <mat-drawer
      id="sideNav"
      #sideNav
      [mode]="deviceService.isMobile() ? 'over' : 'side'"
      position="start"
      [(opened)]="isOpened"
      [disableClose]="!deviceService.isMobile()"
    >
      <glist-layout-feature-sidebar></glist-layout-feature-sidebar>
    </mat-drawer>
    <mat-drawer-content>
      <div class="flex flex-col h-full max-h-full">
        <glist-layout-feature-content class="grow overflow-y-auto"></glist-layout-feature-content>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
