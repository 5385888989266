<mat-toolbar class="transition-all duration-300 !bg-container-fill-primary" [class.!h-0]="!isHeaderVisible && deviceService.isMobile()">
  <div class="w-full flex justify-between items-center">
    <button mat-icon-button (click)="menuClicked.emit()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Glist</span>
    <button mat-icon-button (click)="changeSignInStatus()">
      @if (imageUrl(); as url) {
        <img class="rounded-avatar" [ngSrc]="url" width="48" height="48" />
      } @else {
        <mat-icon>person</mat-icon>
      }
    </button>
  </div>
</mat-toolbar>

<ng-template #defaultUser>
  <mat-icon>person</mat-icon>
</ng-template>
