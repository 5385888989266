@switch (appState) {
  @case (appStateEnum.LOADING) {
    <div class="flex h-full w-full items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>
  }
  @case (appStateEnum.LOADED) {
    <glist-layout-feature-container></glist-layout-feature-container>
  }
  @case (appStateEnum.ERROR) {
    ERROR
  }
}
