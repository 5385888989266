export const environment = {
  firebase: {
    apiKey: 'AIzaSyDMJjRVoHmRfuySBFNzlFvfDHHVBbfYeoE',
    authDomain: 'lobsinger-workspace-dev-e45e7.firebaseapp.com',
    projectId: 'lobsinger-workspace-dev-e45e7',
    storageBucket: 'lobsinger-workspace-dev-e45e7.firebasestorage.app',
    messagingSenderId: '151978710816',
    appId: '1:151978710816:web:2552ec94de49fa5ceca945',
    measurementId: 'G-YNC2SFLW8J'
  }
};
